import React from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.normalWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    }
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
  ul li {
    list-style-type: square;
    line-height: 1.5em;
  }
  img {
    display: block;
    margin-bottom: 2rem;
  }
`

const ExamplePage = ({ data }) => {
  return (
    <Container>
      <SEO title="About Bird Studios Adrianne Barba" description="About Bird Studios Adrianne Barba" />
      <Content>
      <img src="/birdstudios2.jpg" />
      <Title>Thoughtful communication pieces with spirit and soul.</Title>

<p>I have more than 15 years of design and digital experience spanning content creation, web, digital strategy, social media, illustration and print across industries including wellness, food, environment, not-for-profit, lifestyle, property and community development.</p>
<p>Volunteering in my community is important to me: I regularly donate my time to RTRFM radio working on their digital materials and was on the board of Women in Technology WA for several years on the Techtrails initiative, inspiring young people to consider careers in technology.</p>
<p>I’ve achieved tangible results for my clients through consistent and considered branding, planning, designing and publishing content across both digital platforms and print.</p>
<p>I love exploring the character and qualities of each of my projects, helping them flourish by connecting them to their communities for collective benefit.</p>

      <Title>Capabilities</Title>
<ul>
<li>Content creation and strategy</li> 
<li>Graphic design for digital and print</li>
<li>Illustration</li>
<li>Infographics</li>
<li>Web design</li>
<li>EDM</li>
<li>Brand identity & collateral</li>
<li>Copywriting</li>
<li>User experience journeys</li>
<li>Analytics review</li>
</ul>
      </Content>
    </Container>
  )
}

export default ExamplePage
